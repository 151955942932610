exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-bezpieczenstwo-it-js": () => import("./../../../src/pages/oferta/bezpieczenstwo-it.js" /* webpackChunkName: "component---src-pages-oferta-bezpieczenstwo-it-js" */),
  "component---src-pages-oferta-digital-signage-js": () => import("./../../../src/pages/oferta/digital-signage.js" /* webpackChunkName: "component---src-pages-oferta-digital-signage-js" */),
  "component---src-pages-oferta-edukacja-js": () => import("./../../../src/pages/oferta/edukacja.js" /* webpackChunkName: "component---src-pages-oferta-edukacja-js" */),
  "component---src-pages-oferta-gt-cloud-js": () => import("./../../../src/pages/oferta/gt-cloud.js" /* webpackChunkName: "component---src-pages-oferta-gt-cloud-js" */),
  "component---src-pages-oferta-infrastruktura-it-js": () => import("./../../../src/pages/oferta/infrastruktura-it.js" /* webpackChunkName: "component---src-pages-oferta-infrastruktura-it-js" */),
  "component---src-pages-oferta-microsoft-365-js": () => import("./../../../src/pages/oferta/microsoft-365.js" /* webpackChunkName: "component---src-pages-oferta-microsoft-365-js" */),
  "component---src-pages-oferta-outsourcing-it-js": () => import("./../../../src/pages/oferta/outsourcing-it.js" /* webpackChunkName: "component---src-pages-oferta-outsourcing-it-js" */),
  "component---src-pages-oferta-praca-zdalna-js": () => import("./../../../src/pages/oferta/praca-zdalna.js" /* webpackChunkName: "component---src-pages-oferta-praca-zdalna-js" */),
  "component---src-pages-serwis-js": () => import("./../../../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

