/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// font imports from fontsource
// see: https://www.gatsbyjs.com/docs/how-to/styling/using-web-fonts/
// and: https://www.gatsbyjs.com/docs/recipes/styling-css/#using-google-fonts
// and: https://github.com/fontsource/fontsource/tree/main/packages

import React from 'react'
import SimpleReactLightbox from 'simple-react-lightbox'
// USE THE IMPORT BELOW INSTEAD IF YOU ARE USING THE PRO VERSION
// import SimpleReactLightbox from 'simple-react-lightbox-pro'

import "@fontsource/open-sans" // Defaults to weight 400 with all styles included.
import "@fontsource/lato" // Defaults to weight 400 with all styles included.


import "./src/styles/preloader.scss"

 // ES5 way
// exports.onClientEntry = () => {
// ES6 way
export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
      import(`intersection-observer`)
      console.log(`# IntersectionObserver is polyfilled!`)
    }
}
// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>{element}</SimpleReactLightbox>
)